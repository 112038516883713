@use "../../../styles/partials/elements" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;
@use "../../../styles/partials/variables" as *;

.article-page {
  padding: 4rem 1rem;
  width: 100%;
  max-width: 800px;  // Set a max-width for readability on large screens
  margin: 0 auto;    // Center the content

  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 4rem 2rem;
  background: $white;
  width: 100%; 
  text-align: left; 

  &__date {
    color: $primary-grey;
    font-family: Inter;
    font-size: 1rem;
    font-weight: 400;
    line-height: 106.167%;
    letter-spacing: -0.04rem;
  }

  &__title {
    color: $secondary-blue;
    font-family: Inter;
    font-size: 2.5rem; // Slightly larger than card
    font-weight: 600;
    line-height: 106.25%;
    letter-spacing: -0.06rem;
  }

  &__subtitle {
    color: $black;
    font-family: Inter;
    font-size: 2rem; // Slightly larger than card
    font-weight: 600;
    line-height: 106.25%;
    letter-spacing: -0.06rem;
  }

  &__text {
    color: $black;
    font-family: Inter;
    font-size: 1.2rem; // Slightly larger than card for better readability
    font-weight: 400;
    line-height: 200%;
    overflow: visible; // Show the full text, not limited to 10 lines
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0.1875rem;
  }

  .bold-text {
    font-weight: bold;
    font-size: 1.25rem;
  }

  .last-navigation {
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;

    .nav-button {
      padding: 0.5rem 1rem;
      background-color: $secondary-blue;
      color: $white;
      text-decoration: none;
      border-radius: 0.1875rem;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($secondary-blue, 10%);
      }
    }
  }
}
