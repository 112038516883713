@use "../../../styles/partials/elements" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;
@use "../../../styles/partials/variables" as *;

.workers-banner-home {
  display: none;  // Hide by default (mobile viewports)
  justify-content: space-between;
  align-items: center;
  background: url('../../../assets/images/Photos/HomeConstructionBanner.png') no-repeat center center;
  background-size: cover;
  flex-shrink: 0;
  width: 100%;

  // Extra small devices (phones)
  height: 15rem;

  // Medium devices (landscape tablets, 768px and up)
  @include for-md-up {
    display: flex;  // Show on medium devices and up
    height: 20rem;
  }

  // Large devices (desktops, 1024px and up)
  @include for-lg-up {
    height: 45rem;
  }

  // Extra large devices (big desktops, 1200px and up)
  @include for-xl-up {
    height: 45rem;
  }
}
