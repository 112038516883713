// BREAKPOINT VARIABLES

$breakpoint-xs: 320px; // Extra small devices (portrait phones)
$breakpoint-sm: 480px; // Small devices (landscape phones)
$breakpoint-md: 768px; // Medium devices (tablets)
$breakpoint-lg: 1024px; // Large devices (desktops, laptops)
$breakpoint-hd: 1440px; // Extra large devices (large desktops, TVs)
$breakpoint-xl: 1920px; // Extra large devices (large desktops, TVs)

// PRIMARY COLOURS

$primary-blue: #00007C;
$primary-grey: #666666;
$background-grey: #F6F6F6;
$black: #000000;
$white: #FFFFFF;

// SECONDARY COLOURS

$secondary-blue: #1C67CB;
$secondary-grey: #A6A6A6;
