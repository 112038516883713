@use "../../styles/partials/elements" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.navbar {
  display: flex;
  justify-content: center; 
  align-items: center;
  width: 100%;
  height: 6.875rem;
  background-color: $white;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 1rem;
  box-shadow: 0 2px 4px 0 rgba(0.3,0.3,0.3,0.3); // This gives a subtle shadow effect

  &.menu-open {
    background-color: $primary-blue;
  }

  &__logo-link {
    z-index: 20;
  }

  &__logo {
    width: auto;
    height: 4.507rem;
  }

  &__menu-icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 20;
  }

  &__links {
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    padding: 1rem 0 2.5rem;
    background-color: $primary-blue;
    width: 100%;
    position: absolute;
    top: 6.875rem;
    left: 0;
    z-index: 5;

    &.visible {
      display: flex;
    }
    }

    &__link-item {
      color: $white;
      text-align: center;
      font-family: Open-Sans-Bold;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.5rem;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
  }

  @include for-md-up {
    padding: 1rem 5rem;
    justify-content: space-between; 

    &__menu-icon {
      display: none;
    }

    &__links {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 2rem;
      position: static;
      background-color: transparent;
      padding: 0;

      
    }

    &__link-item {
      color: $black;
      text-align: right;
      font-family: Inter-Semi-Bold;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.125rem;
    }
  }

  @include for-lg-up {
    padding: 0rem 7.5rem;
  }

  @include for-xl-up {
    padding: 0rem 22rem;

    &__links {
      gap: 3.5rem;
    }
  }
}
