@use "../../styles/partials/elements" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.whistleblower-container {
  width: 100%;
  padding: 4rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .whistleblower-title {
    color: $primary-blue;
    font-family: Inter-Extra-Bold;
    font-size: 2.5rem;
    font-weight: 800;
    line-height: 1.8;
    text-align: center; // Default alignment for mobile
    margin-bottom: 2rem;
  }

  .whistleblower-content {
    width: 100%;
    display: flex;
    flex-direction: column; // Default for mobile devices
    gap: 2rem;

    .whistleblower-textbox,
    .whistleblower-photo-container {
      flex: 1;
    }

    .whistleblower-textbox {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .whistleblower-subtitle {
        color: #1C67CB;
        font-family: Inter-Bold;
        font-size: 2rem;
        font-weight: 700;
      }

      .whistleblower-text {
        color: $black;
        font-family: Open-Sans;
        font-size: 1rem;
        line-height: 1.5;
      }

      .whistleblower-link {
        color: $primary-blue;
        font-family: Inter-Bold;
        font-size: 1rem;
        font-weight: 700;
        text-decoration: underline;
      }
    }

    .whistleblower-photo-container {
      .whistleblower-photo {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
}

/* Media Queries */
@include for-lg-up {
  .whistleblower-container {
    align-items: flex-start; // Align items to the start on larger viewports
    padding: 4rem 10rem;

    .whistleblower-title {
      text-align: left; // Align title to the left
      font-size: 3rem;
    }

    .whistleblower-content {
      flex-direction: row; // Switch to row layout
      align-items: stretch; // Stretch items to fill container height
      gap: 2rem;

      .whistleblower-textbox,
      .whistleblower-photo-container {
        flex: 1;
      }

      .whistleblower-photo-container {
        .whistleblower-photo {
          width: 100%;
          height: 100%; // Make the image fill the container
          object-fit: cover; // Ensure the image maintains its aspect ratio
        }
      }
    }
  }
}

@include for-xl-up {

  .whistleblower-title {
    text-align: left; // Align title to the left
    font-size: 3.5rem;
  }

  .whistleblower-container {
    padding: 6.88rem 22rem;
  }
}
