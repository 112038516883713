@use "../../styles/partials/elements" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.privacy-policy-container {
    display: flex;
    flex-direction: column;
    padding: 4rem 1.25rem;  // Default padding for mobile viewports
    gap: 1.5rem;
    max-width: 65rem;
    margin: 0 auto;

    @include for-lg-up {
        padding: 3.67rem min(7.5rem, max(3rem, 5vw));  // Adjusted padding for large viewports
    }

    @include for-xl-up {
        padding: 6.88rem min(22.5rem, max(15rem, 10vw));  // Adjusted padding for XL viewports
    }

    .policy-title {
        color: $primary-blue;
        font-family: Inter-Extra-Bold;
        font-size: 2.625rem;
        font-weight: 800;
        line-height: 2.75rem;
        text-align: left;
    }

    .policy-date {
        font-style: italic;
        font-weight: 600;
    }


    .policy-subtitle {
        color: $primary-blue;
        font-family: Inter-Bold;
        font-size: 1.75rem;
        font-weight: 700;
    }

    .policy-intro,
    .policy-text {
        color: $black;
        font-family: Open-Sans;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 1rem;
    }

    .policy-text-bold {
        text-decoration: underline;
    }

    .policy-list {
        list-style-type: disc;
        padding-left: 2rem;

        li {
            margin-bottom: 0.5rem;
            color: $black;
            font-family: Open-Sans;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
        }
    }
}
