@use "./partials/elements" as *;
@use "./partials/mixins" as *;
@use "./partials/typography" as *;
@use "./partials/variables" as *;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-width: 320px;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

.hidden {
  display: none;
}

// CookieConsent styles
.cookie-consent-banner {
  background-color: $white !important; // Use !important to override default styles if necessary
  color: $black !important;
  font-family: "YourFontFamily"; // Replace with your actual font family
  padding: 1rem !important; // Standard padding for cookie banner
}

// Styling for the link within the cookie consent
.cookie-link {
  color: $secondary-blue !important;
  text-decoration: underline;

  &:hover {
    color: darken(
      $secondary-blue,
      10%
    ) !important; // Darken on hover for example
  }
}

// Styling for the button within the cookie consent
.cookie-button {
  color: $white !important;
  background-color: $primary-blue !important;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem; // Replace with your actual font size
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: darken(
      $primary-blue,
      10%
    ) !important; // Darken on hover for example
  }
}
