@use "variables" as *;
@use "typography" as *;

@mixin for-xs-up {
  @media screen and (min-width: $breakpoint-xs) {
    @content;
  }
}

@mixin for-sm-up {
  @media screen and (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin for-md-up {
  @media screen and (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin for-hd-up {
  @media screen and (min-width: $breakpoint-hd) {
    @content;
  }
}

@mixin for-lg-up {
  @media screen and (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin for-xl-up {
  @media screen and (min-width: $breakpoint-xl) {
    @content;
  }
}

// TEXT-STYLE MIXINS

@mixin text__page-header {
  font-family: Inter-Extra-Bold;
  font-size: 2rem;
  line-height: 1.931rem;
  font-weight: 800;

  @include for-md-up() {
    font-family: Inter-Extra-Bold;
    font-size: 2rem;
    line-height: 1.625rem;
    font-weight: 800;
  }

  @include for-lg-up() {
    font-family: Inter-Extra-Bold;
    font-size: 2.75rem;
    line-height: 2.852rem;
    font-weight: 800;
  }

  @include for-xl-up() {
    font-family: Inter-Extra-Bold;
    font-size: 3.75rem;
    line-height: 3.625rem;
    font-weight: 800;
  }
}

@mixin text__subheader {
  font-family: Inter-Semi-Bold;
  font-size: 2rem;
  line-height: 2.125rem;
  letter-spacing: -3%;
  font-weight: 600;

  @include for-md-up() {
    font-family: Inter-Semi-Bold;
    font-size: 1.52rem;
    line-height: 1.713rem;
    letter-spacing: -3%;
    font-weight: 600;
  }

  @include for-lg-up() {
    font-family: Inter-Semi-Bold;
    font-size: 1.52rem;
    line-height: 1.713rem;
    letter-spacing: -3%;
    font-weight: 600;
  }

  @include for-xl-up() {
    font-family: Inter-Semi-Bold;
    font-size: 2.375rem;
    line-height: 2.625rem;
    letter-spacing: -3%;
    font-weight: 600;
  }
}

@mixin text__links {
  font-family: Open-Sans-Bold;
  font-size: 1.5rem;
  line-height: 0rem;
  font-weight: 700;

  @include for-md-up() {
    font-family: Open-Sans-Bold;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
  }

  @include for-lg-up() {
    font-family: Open-Sans-Bold;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
  }

  @include for-xl-up() {
    font-family: Open-Sans-Bold;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
  }
}

@mixin text__table-header {
  font-family: Inter;
  font-size: 0.688rem;
  line-height: 1rem;
  font-weight: 600;
}

@mixin text__body-large {
  font-family: Open-Sans;
  font-size: 1rem;
  line-height: 1.875rem;
  font-weight: 400;

  @include for-md-up() {
    font-family: Open-Sans;
    font-size: 1rem;
    line-height: 1.875rem;
    font-weight: 400;
  }

  @include for-lg-up() {
    font-family: Open-Sans;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  @include for-xl-up() {
    font-family: Open-Sans;
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 400;
  }
}

@mixin text__body-medium {
  font-family: Inter;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 400;

  @include for-md-up() {
    font-family: Inter;
    font-size: 1.125rem;
    line-height: 1.813rem;
    font-weight: 400;
  }

  @include for-lg-up() {
    font-family: Inter;
    font-size: 1.125rem;
    line-height: 1.813rem;
    font-weight: 400;
  }

  @include for-xl-up() {
    font-family: Inter;
    font-size: 1.125rem;
    line-height: 1.813rem;
    font-weight: 400;
  }
}

@mixin text__body-small {
  font-family: Open-Sans;
  font-size: 0.688rem;
  line-height: 1rem;
  font-weight: 400;
}
