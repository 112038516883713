@use "../../../styles/partials/elements" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;
@use "../../../styles/partials/variables" as *;

// Base styles (Mobile)
.whatwedo {
  display: flex;
  height: fit-content;
  padding: 4rem 1.25rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  flex-shrink: 0;
  width: 100%;
}

.whatwedo__content {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1rem;
  text-align: left;
}

.whatwedo__title {
  color: $primary-blue;
  @include text__page-header;
}

.whatwedo__text {
  color: $black;
  @include text__body-large;
}

.whatwedo__list {
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding-top: 1.25rem;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
}

.whatwedo__list-left,
.whatwedo__list-right {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.whatwedo__list-item {
  display: flex;
  height: 3rem;
  align-items: center;
  gap: 1rem;
}

.whatwedo__list-item-icon {
  width: 2rem;
  height: 2rem;
}

.whatwedo__list-item-text {
  color: $black;
  font-family: Open-Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  word-wrap: break-word;
}

.whatwedo__image {
  display: none; // Hide the image on mobile viewports
  align-items: center;
  justify-content: center;
}

.whatwedo__image-photo {
  width: 100%;
  height: auto;
  object-fit: cover;
}

// medium devices and up
@include for-md-up {
  .whatwedo {
    padding: 3.67rem 5rem;
  }

  .whatwedo__content {
  }

  .whatwedo__image {

  }

  .whatwedo__list {
    flex-direction: row;
    gap: 1.5rem;
    justify-content: space-between;
    padding-top: 0rem;
  }

  .whatwedo__list-left,
  .whatwedo__list-right {
    gap: .75rem;
  }
}

// Desktop devices
@include for-lg-up {
  .whatwedo {
    flex-direction: row;
    align-items: center;
    padding: 3rem 7.5rem;
    gap: 1.5rem;
  }

  .whatwedo__content {
    width: 50%;
    gap: 1.5rem;
  }

  .whatwedo__title {
    font-size: 2.75rem;
    line-height: 96.667%;
  }

  .whatwedo__text {
    font-size: 0.875rem;
  }

  .whatwedo__image {
    display: block;
    width: 50%;
  }

  .whatwedo__list {
    flex-direction: row;
    gap: 1.5rem;
    justify-content: space-between;
    padding-top: 0rem;
  }

  .whatwedo__list-item-text {
    font-size: 0.875rem;
  }

  .whatwedo__list-left,
  .whatwedo__list-right {
    gap: .75rem;
  }
}

// Extra large devices
@include for-xl-up {
  .whatwedo {
    padding: 6.88rem 22.5rem;
    gap: 2.5rem;
  }

  .whatwedo__title{
    font-size: 3.75rem;
  }

  .whatwedo__text {
    font-size: 1rem;
  }

  .whatwedo__list-item-text {
    font-size: 1rem;
  }

  
  .whatwedo__content {
    width: 50%;
  }

  .whatwedo__image {
    width: 50%;
  }
}
