@use "../../styles/partials/elements" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.footer {
  background: $primary-blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $white;

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4375rem;
    
  }

  &__logo-address-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  &__logo-container {
    display: none; // hidden for mobile
  }

  &__logo {
    width: auto;
    height: auto;
  }

  &__address-container {
    display: none; // hidden for mobile
  }

  &__address {
    color: $white;
    text-align: center;
  }

  &__links-icons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  &__links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4375rem;
    padding-bottom: 1.5rem;
  }

  &__link {
    color: $white;
    text-align: center;
    font-family: Open-Sans-Bold;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 35.556%;
  }

  &__icons-container {
    display: flex;
    gap: 1rem;
    padding-bottom: 1.5rem;
  }

  &__icon-link {
    display: inline-block;
  }

  &__icon {
    width: auto;
    height: auto;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding: 2rem 1rem;
    border-top: 0.063rem solid $white;
  }

  &__copyright {
    color: $white;
    font-family: Open-Sans;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 91.429%;
  }

  &__terms {
    display: flex;
    gap: 1rem;
  }

  &__term-link {
    color: $white;
    font-family: Open-Sans;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 91.429%;
  }

  // DESKTOP STYLING
  @include for-lg-up {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem 7.5rem;

    &__top {
      flex-direction: row;
      align-items: start;
      justify-content: space-between;
      width: 100%;
      padding: 0rem;
      padding-bottom: 2rem;
    }

    &__logo-address-container {
      flex-direction: row;
      gap: 3.25rem;
      align-items: start;
    }

    &__logo-container {
      display: block;
    }

    &__logo {
      width: 5rem;
      height: auto;
      flex-shrink: 0;
    }

    &__address-container {
      width: 8.875rem;
      height: auto;
      flex-shrink: 0;
      text-align: left;
      display: block;
    }

    &__address {
      color: $white;
      font-family: Open-Sans;
      font-size: 0.65rem;
      font-weight: 400;
      line-height: 128.571%;
      text-align: left;
    }

    &__links-icons-container {
      flex-direction: row;
      gap: 3.25rem;
    }

    &__links-container {
      flex-direction: row;
      gap: 5rem;
      margin-right: 4rem;
    }

    &__link {
      color: $white;
      font-family: Open-Sans-Bold;
      font-size: .75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
    }

    &__icons-container {
      flex-direction: row;
      gap: 2rem;
    }

    &__icon {
      width: auto;
      height: auto;
    }

    &__bottom {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0rem;
      padding-top: 2rem;
    }

    &__copyright {
      color: $white;
      font-family: Open-Sans;
      font-size: 0.65rem;
      font-weight: 400;
      line-height: 91.429%;
    }

    &__terms {
      display: flex;
      gap: 1rem;
    }

    &__term-link {
      color: $white;
      font-family: Open-Sans;
      font-size: 0.655rem;
      font-weight: 400;
      line-height: 91.429%;
    }
  }

  @include for-xl-up {
    padding: 3.12rem 22.38rem;

    &__top {
      flex-direction: row;
      align-items: start;
      justify-content: space-between;
      width: 100%;
      padding: 0rem;
      padding-bottom: 2rem;
    }

    &__logo-address-container {
      flex-direction: row;
      gap: 3.25rem;
      align-items: center;
    }

    &__logo-container {
      display: block;
    }

    &__logo {
      width: 7rem;
      height: auto;
      flex-shrink: 0;
    }

    &__address-container {
      width: 13.125rem;
      height: auto;
      flex-shrink: 0;
      text-align: left;
      display: block;
    }

    &__address {
      color: $white;
      font-family: Open-Sans;
      font-size: 1rem;
      font-weight: 400;
      line-height: 128.571%;
      text-align: left;
    }

    &__links-icons-container {
      flex-direction: row;
      gap: 3.25rem;
    }

    &__links-container {
      flex-direction: row;
      gap: 10rem;
      margin-right: 7rem;
    }

    &__link {
      color: $white;
      font-family: Open-Sans-Bold;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      width: 5rem;
    }

    &__icons-container {
      flex-direction: row;
      gap: 2rem;
      align-items: center;
      align-content: center;
    }

    &__icon {
      width: 2rem;
      height: 2rem;
    }

    &__bottom {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0rem;
      padding-top: 2rem;
    }

    &__copyright {
      color: $white;
      font-family: Open-Sans;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 91.429%;
    }

    &__terms {
      display: flex;
      gap: 5rem;
    }

    &__term-link {
      color: $white;
      font-family: Open-Sans;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 91.429%;
    }
  }
}
