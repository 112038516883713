@use "../../../styles/partials/elements" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;
@use "../../../styles/partials/variables" as *;


.accessibilityplan-banner {
    position: relative;
    width: 100%;
    height: 14.38rem;
    background-image: url("../../../assets/images/Photos/AboutUsMobile.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4rem 1.25rem;
  
    // Adding a blue overlay
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $primary-blue;
      opacity: 0.85; 
      z-index: 1; 
    }
  
    &__container, &__title {
      position: relative; // Ensure these elements are above the overlay
      z-index: 2;
    }
    
  
    &__container {
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
    }
  
    &__title {
      color: $white;
      font-family: Inter-Extra-Bold;
      font-size: 3.3125rem;
      font-weight: 800;
      line-height: 2.87844rem;
      letter-spacing: -0.09938rem;
      text-align: left;
    }
  }
  
  @include for-sm-up {
    .accessibilityplan-banner {
      padding: 4rem 2rem;
    }
  }
  
  @include for-md-up {
    .accessibilityplan-banner {
      padding: 3.67rem 5rem;
      height: 16.43rem;
  
      &__title {
        font-size: 5rem;
      }
    }
  }
  
  @include for-lg-up {
    .accessibilityplan-banner {
      padding: 3.67rem 7.2rem;
      height: 18rem;
  
      &__title {
        font-size: 4.75rem;
      }
    }
  }
  
  @include for-xl-up {
    .accessibilityplan-banner {
      padding: 6.88rem 22.3rem;
  
      &__title {
        font-size: 7rem;
      }
    }
  }

.accessibility-plan-container {
    display: flex;
    flex-direction: column;
    padding: 4rem 1.25rem;  // Default padding for mobile viewports
    gap: 1.5rem;
    max-width: 65rem;
    margin: 0 auto;

    @include for-lg-up {
        // padding: 3.67rem min(7.5rem, max(3rem, 5vw));  // Adjusted padding for large viewports
    }

    @include for-xl-up {
        // padding: 6.88rem min(22.5rem, max(15rem, 10vw));  // Adjusted padding for XL viewports
    }

    .plan-title {
        color: $primary-blue;
        font-family: Inter-Extra-Bold;
        font-size: 2.625rem;
        font-weight: 800;
        line-height: 2.75rem;
        text-align: left;
    }

    .plan-date {
        font-style: italic;
        font-weight: 600;
    }

    .plan-subtitle {
        color: $primary-blue;
        font-family: Inter-Bold;
        font-size: 1.75rem;
        font-weight: 700;
    }

    .plan-intro,
    .plan-text {
        color: $black;
        font-family: Open-Sans;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 1rem;
    }

    .plan-text-bold {
        text-decoration: underline;
    }

    .plan-list {
        list-style-type: disc;
        padding-left: 2rem;

        li {
            margin-bottom: 0.5rem;
            color: $black;
            font-family: Open-Sans;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
        }
    }

    .plan-link {
        color: $primary-blue;
        font-family: Open-Sans;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5;
    }

    .plan-link-bold {
        font-weight: 700;
    }
}
