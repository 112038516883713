@use "../../../styles/partials/elements" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;
@use "../../../styles/partials/variables" as *;

.get-in-touch {
  background-image:linear-gradient(
        rgba(255, 255, 255, 0.35),
        rgba(255, 255, 255, 0.35)
      ), url("../../../assets/images/Photos/GetInTouchPhoto.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 4rem 1rem;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 1rem;
    background-color: $white;
  }

  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.38rem;
  }

  &__header {
    @include text__page-header;
    color: $primary-blue;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 1.38rem;
  }

  &__list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
  }

  &__list-image {
    width: 3.5rem;
    height: 3.5rem;
    flex-shrink: 0;
  }

  &__list-text {
    color: #37393f;
    font-family: Open-Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 112.5% */
  }

  &__right {
    flex: 1;
    display: none;

    @include for-md-up {
      display: block;
    }
  }

  &__mobile-map {
    display: block;

    @include for-md-up {
      display: none;
    }
  }

  .bold-text {
    font-weight: bold;
  }

  @include for-md-up {
    padding: 3.88rem 5rem;

    &__container {
      padding: 3rem 3rem;
    }

    &__list {
      align-items: flex-start;
      height: 100%;
      padding-top: 1.5rem 0rem;
      padding-right: 1.25rem;
    }
  }

  @include for-md-up {
    padding: 3.88rem 5rem;

    &__container {
      padding: 3rem 3rem;
    }

    &__list {
      align-items: flex-start;
      height: 100%;
      padding-top: 1.5rem 0rem;
      padding-right: 1.25rem;
    }
  }

  @include for-lg-up {
    display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 7.5rem;

    &__container {
      padding: 3rem 3rem;
      width: 100%;
    }

    &__list {
      align-items: flex-start;
      height: 100%;
      padding-top: 1.5rem 0rem;
      padding-right: 1.25rem;
    }
  }

  @include for-xl-up {
    display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 7.5rem 22.5rem;

    &__container {
      padding: 3rem 3rem;
      //   width: 60rem;
    }

    &__list {
      align-items: flex-start;
      height: 100%;
      padding-top: 1.5rem 0rem;
      padding-right: 1.25rem;
    }

    &__list-text {
      font-family: Open-Sans;
      font-size: 1.15rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }

    .bold-text {
      font-weight: bold;
      font-size: 1.15rem;
    }
  }
}
