@use "../../../styles/partials/elements" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;
@use "../../../styles/partials/variables" as *;

.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1.25rem;
  gap: 1.5rem;

  @include for-md-up {
    padding: 3.67rem 5rem; // Adjusted padding for medium viewports
  }

  @include for-lg-up {
    padding: 3.67rem 7.5rem; // Adjusted padding for large viewports
  }

  @include for-xl-up {
    padding: 6.88rem 22.5rem; // Adjusted padding for extra-large viewports
  }


  .form-content,
  .contact-form {
    max-width: none;
    width: 100%; // Ensure full width
    
  }

  .form-content {
    max-width: 100%; // Ensure full width for mobile
    gap: 1.5rem;

    .contact-form__title {
        margin-bottom: 1rem;
      color: $primary-blue;
      @include text__page-header;
    }

    .contact-form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: 100%;

      &__group {
        display: flex;
        flex-direction: column; // Stack inputs on mobile
        gap: 1.5rem;
        width: 100%;

        .contact-form__input {
          width: 100%; // Full width for each input on mobile
        }
      }

      .contact-form__input,
      .contact-form__textarea,
      .contact-form__button {
        text-align: left;
      }

      .contact-form__input,
      .contact-form__textarea {
        display: flex;
        padding: 1.25rem 1.5625rem;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;
        border-radius: 0.1875rem;
        border: 0.5px solid $primary-grey;
        background: $white;
        color: $primary-grey;
        font-family: Open-Sans;
        font-size: 1.125rem;
        line-height: 133.333%;
      }

      .contact-form__textarea {
        display: flex;
        height: 9.6875rem;
        padding: 1.25rem 1.5625rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 2.3125rem;
        align-self: stretch;
      }

      .contact-form__button {
        display: flex;
        width: 12.5rem;
        height: 3.75rem;
        padding: 1.125rem 1.66731rem;
        justify-content: center; // Horizontal centering
        align-items: center;
        gap: 0.33344rem;
        cursor: pointer;
        align-self: flex-start;
        border-radius: 0.1875rem;
        border: 0.5px solid $secondary-blue;
        background: $secondary-blue;
        color: $white;
        font-family: Inter-Semi-Bold;
        font-size: 1rem;
        font-weight: 700;

        &:hover {
          background-color: $primary-blue;
        }
      }

      @include for-md-up {
        &__group {
          flex-direction: row; // Return to side-by-side layout for larger screens
        }

        .contact-form__input,
        .contact-form__textarea {
          font-size: 1.25rem;
        }

        .contact-form__button {
          width: 15rem;
        }
      }

      @include for-lg-up {
        width: 100%;

        .contact-form__title {
          margin-bottom: 3rem;
      }
      }
    }
  }
}
