@font-face {
    font-family: 'Inter';
    src: url("../../assets/fonts/Inter-Regular.woff2");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter-Bold';
    src: url("../../assets/fonts/Inter-Bold.woff2");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter-Extra-Bold';
    src: url("../../assets/fonts/Inter-ExtraBold.woff2");
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter-Semi-Bold';
    src: url("../../assets/fonts/Inter-SemiBold.woff2");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open-Sans';
    src: url("../../assets/fonts/OpenSans-Regular.woff2");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open-Sans-Bold';
    src: url("../../assets/fonts/OpenSans-Bold.woff2");
    font-weight: 700;
    font-style: normal;
  }

  
