@use "../../styles/partials/elements" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.main {
  display: flex;
  flex-direction: column;
  align-items: stretch; // Ensures that every item takes the full width
  width: 100%;
  min-height: 100vh; // Ensures the main element takes at least the full height of the viewport
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
}

// Media queries for responsiveness

// Extra small devices (portrait phones, 320px and up)
@include for-xs-up {
  // styles for 320px and up go here
}

// Small devices (landscape phones, 480px and up)
@include for-sm-up {
  // styles for 480px and up go here
}

// Medium devices (tablets, 768px and up)
@include for-md-up {
  // styles for 768px and up go here
}

// Large devices (desktops, 1024px and up)
@include for-lg-up {
  // styles for 1024px and up go here
}

// Extra large devices (large desktops, 1280px and up)
@include for-xl-up {
  // styles for 1280px and up go here
}
