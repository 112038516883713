@use "../../../styles/partials/elements" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;
@use "../../../styles/partials/variables" as *;

.road-safety-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 4rem 1.25rem; 
}

.roadsafety {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 2rem;

  &__image-container,
  &__content {
    flex: 1;
  }

  &__image-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    .image-RoadSafety {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: flex-start;
    gap: 1.5rem;

    &-header {
      @include text__page-header;
      color: $primary-blue;
    }

    &-text {
      @include text__body-large;
      color: $black;
    }

    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12.5rem;
      height: 3.75rem;
      padding: 0.66694rem 1.66731rem;
      background: $secondary-blue;
      border: solid 1px $secondary-blue;
      color: $white;
      text-align: center;
      font-family: Inter-Semi-Bold;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.02rem;

      &:hover {
        background: darken($secondary-blue, 10%); // Darkens the button color on hover
        border-color: darken($secondary-blue, 10%);
        cursor: pointer;
      }
  
      &:active {
        background: darken($secondary-blue, 20%); // Further darkens the button color when clicked
        border-color: darken($secondary-blue, 20%);
      }
    }
  }

  

    // Added for mobile left alignment
    @include for-xs-up {
      align-items: flex-start;
      text-align: left;
    }
  }


/// Extra small devices (portrait phones, 320px and up)
@include for-xs-up {
  .road-safety-container {
    padding: 4rem 1.25rem;
  }

  .roadsafety {
    flex-direction: column;
    gap: 4.5rem;
    &__image-container {
      display: none;
    }
  }
}

// Small devices (landscape phones, 480px and up)
@include for-sm-up {
  .road-safety-container {
    padding: 4rem 1.25rem;
  }
}

// Medium devices (tablets, 768px and up)
@include for-md-up {
  .road-safety-container {
    padding: 3.67rem 5rem;
  }
  .roadsafety {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  
    &__image-container,
    &__content {
      flex: 1;
    }
  
    &__image-container {
      display: flex;
      justify-content: center; // Centers horizontally
      align-items: center;     // Centers vertically
      padding-top: 0rem;
      padding-bottom: 0rem;
      height: 100%;
      .image-RoadSafety {
          width: 100%;
          height: auto;
          object-fit: cover;
      }
    }  

}

// Large devices (desktops, 1024px and up)
@include for-lg-up {
  .road-safety-container {
    padding: 3.67rem 7.5rem;
  }

  .roadsafety {
    &__image-container {
      display: block;
    }
    flex-direction: row;
    justify-content: space-between;
    &__content {
      align-items: flex-start;
      text-align: left;
    }
    &__content-button {
      align-self: start;
    }
  }
}

// Extra large devices (large desktops, 1280px and up)
@include for-xl-up {
  .road-safety-container {
    padding: 6.88rem 22rem;
  }

  
  .roadsafety {
    &__image-container {
      display: block;
    }
    flex-direction: row;
    justify-content: space-between;
    &__content {
      align-items: flex-start;
      text-align: left;
    }
    &__content-button {
      align-self: start;
    }
  }
}

.roadsafety {
  max-width: 120rem;
}

@media (max-width: 318px) {
  .roadsafety:not(.large-up) .roadsafety__image-container {
    display: none !important;
  }
}
}