@use "../../../styles/partials/elements" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;
@use "../../../styles/partials/variables" as *;

.mediabanner {
  background-image: url("../../../assets/images/Photos/MediaBanner.jpg");
  position: relative;
  width: 100%;
  height: 14.38rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem 1.25rem;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary-blue;
    opacity: 0.85; 
    z-index: 1;
  }

  &__container, &__title {
    position: relative; // Ensure these elements are above the overlay
    z-index: 2;
  }


  &__container {
    display: flex;
    justify-content: start; // Aligns content to the left
    align-items: center;
    width: 100%; // Full width
  }

  &__title {
    color: $white;
    font-family: Inter-Extra-Bold;
    font-size: 3.3125rem;
    font-weight: 800;
    line-height: 2.87844rem;
    letter-spacing: -0.09938rem;
    text-align: left;
  }
}

@include for-sm-up {
  .mediabanner {
    padding: 4rem 1.8rem; // Adjusted padding
  }
}

@include for-md-up {
  .mediabanner {
    padding: 3.67rem 4.7rem; // Adjusted padding
    height: 16.43rem;

    &__title {
      font-size: 5rem;
    }
  }
}

@include for-lg-up {
  .mediabanner {
    padding: 3.67rem 7.2rem;
    height: 18rem;

    &__title {
      font-size: 4.75rem;
    }
  }
}

@include for-xl-up {
  .mediabanner {
    padding: 6.88rem 22.3rem;

    &__title {
      font-size: 7rem;
    }
  }
}
