@use "../../../styles/partials/elements" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;
@use "../../../styles/partials/variables" as *;

.news {
  padding: 4rem 1.25rem;

  @include for-md-up {
    padding: 3.88rem 5rem;
  }

  @include for-lg-up {
    padding: 3rem 7rem;
  }

  @include for-xl-up {
    padding: 6.88rem 22.5rem;
  }

  &__cards {
    &__header {
      color: $primary-blue;
      @include text__page-header;
      margin-bottom: 3rem;
    }

    .grid-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
      width: 100%;

      .card-link {
        width: 100%;
        display: block;
        margin-bottom: 2rem;

        @include for-md-up {
          display: inline-block;
          max-width: calc(50% - 1rem);
        }
      }

      .card,
      .article-page {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background: $white;
        width: 100%;
        text-align: left;

        &__text,
        &__date,
        &__title {
          flex-shrink: 0;
        }

        &__image {
          flex-grow: 1;
          width: 100%;
          height: 20rem;
          max-width: 100%;
          margin: 0 auto;
          object-fit: cover;
          border-radius: 0.1875rem;
        }

        &__title {
          color: $secondary-blue;
          font-family: Inter;
          font-size: 2rem;
          font-weight: 600;
          line-height: 106.25%;
          letter-spacing: -0.06rem;
        }

        &__date {
          color: $primary-grey;
          font-family: Inter;
          font-size: 1rem;
          font-weight: 400;
          line-height: 106.167%;
          letter-spacing: -0.04rem;
        }

        &__text {
          color: $black;
          font-family: Inter;
          font-size: 1rem;
          font-weight: 400;
          line-height: 200%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }

        .article-page__link {
          color: $primary-blue;
          text-decoration: none;
          transition: color 0.3s;

          &:hover {
            color: darken($primary-blue, 10%);
          }
        }
      }
    }

    @include for-md-up {
      .grid-container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .card__title {
        height: 8rem;
      }
    }

    // Not sure why this needs to be nested like this but if you change it the grid container goes to column
    // Don't touch it
    @include for-md-up {
      .news__cards__header {
        margin-bottom: 2.5rem;
      }
    }

    @include for-hd-up {
      .card {
        margin-bottom: 2rem;
      }

      .card__title {
        height: 6rem;
      }
      
    }

    @include for-lg-up {
      .news__cards__header {
        margin-bottom: 1.25rem;
      }

      .card__title {
        height: 6rem;
      }
    }

    @include for-xl-up {
      .news__cards__header {
        margin-bottom: 3rem;
      }

      .card__title {
        height: 5.5rem;
      }
    }
  }
}
